import { React, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';

import 'assets/scss/page/_general_setting.scss';
import validationSchema from './GeneralPostSeoValidation';
import { useGetPostSeoData, useStorePostSeoData } from 'hooks';

import { AlertCommon, CommonHelmet, NavItemsCommon, TNButton } from 'common';
import { imagePreviewFormik } from 'helpers';
import { DASHBOARD, mailNotificationNavItems } from 'config';

const GeneralPostSeoSettingPage = ({ t }) => {
  const navigate = useNavigate();
  const faviconRef = useRef();
  const [seoData, setSeoData] = useState();

  useGetPostSeoData(
    'general_post',
    ({ data: setting }) => {
      const dataStore = {
        general_post_page_general_seo_title: setting?.general_post_page_general_seo_title,
        general_post_page_general_seo_description:
          setting?.general_post_page_general_seo_description,
        general_post_details_general_seo_title: setting?.general_post_details_general_seo_title,
        general_post_details_general_seo_description:
          setting?.general_post_details_general_seo_description,
        general_post_page_general_favicon_logo: setting?.general_post_page_general_favicon_logo,
      };
      setSeoData(dataStore); // Update the seoData once it's fetched
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  const {
    mutate: doStorePostSeoData,
    error: addGDList,
    isError: addGDError,
  } = useStorePostSeoData((response) => {
    toast.success(response.message);
    setTimeout(function () {
      navigate(DASHBOARD);
    }, 1500);
  });

  /**
   * !This block will call on click cancel
   */
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(DASHBOARD, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(DASHBOARD, { replace: true });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      general_post_page_general_seo_title: seoData?.general_post_page_general_seo_title || '',
      general_post_page_general_seo_description:
        seoData?.general_post_page_general_seo_description || '',
      general_post_details_general_seo_title: seoData?.general_post_details_general_seo_title || '',
      general_post_details_general_seo_description:
        seoData?.general_post_details_general_seo_description || '',
      general_post_page_general_favicon_logo: seoData?.general_post_page_general_favicon_logo || '',
    },
    validationSchema,
    onSubmit: (values) => {
      doStorePostSeoData(values);
    },
  });

  return (
    <>
      <CommonHelmet
        title={'page.seo_title_post_seo_setting'}
        description={'page.seo_description_post_seo_setting'}
      />
      <NavItemsCommon navItemName={mailNotificationNavItems} t={t} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addGDError}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box p-3">
        <h1 className="page-heading-center">{t('page.general_post_seo_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_general_post_page_general_seo_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.general_post_page_general_seo_title &&
                        formik.errors.general_post_page_general_seo_title
                          ? 'form-field-error'
                          : formik.touched.general_post_page_general_seo_title &&
                            !formik.errors.general_post_page_general_seo_title
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_post_page_general_seo_title"
                      placeholder={t(
                        'page.general_setting_general_post_page_general_seo_title_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'general_post_page_general_seo_title',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_post_page_general_seo_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_post_page_general_seo_title &&
                      formik.errors.general_post_page_general_seo_title ? (
                        <div>{t(formik.errors.general_post_page_general_seo_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_general_post_page_general_seo_description_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.general_post_page_general_seo_description &&
                        formik.errors.general_post_page_general_seo_description
                          ? 'form-field-error'
                          : formik.touched.general_post_page_general_seo_description &&
                            !formik.errors.general_post_page_general_seo_description
                          ? 'form-field-success'
                          : '')
                      }
                      as="textarea"
                      rows={3}
                      type="text"
                      name="general_post_page_general_seo_description"
                      placeholder={t(
                        'page.general_setting_general_post_page_general_seo_description_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'general_post_page_general_seo_description',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_post_page_general_seo_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_post_page_general_seo_description &&
                      formik.errors.general_post_page_general_seo_description ? (
                        <div>{t(formik.errors.general_post_page_general_seo_description)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_general_post_details_general_seo_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.general_post_details_general_seo_title &&
                        formik.errors.general_post_details_general_seo_title
                          ? 'form-field-error'
                          : formik.touched.general_post_details_general_seo_title &&
                            !formik.errors.general_post_details_general_seo_title
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="general_post_details_general_seo_title"
                      placeholder={t(
                        'page.general_setting_general_post_details_general_seo_title_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'general_post_details_general_seo_title',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_post_details_general_seo_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_post_details_general_seo_title &&
                      formik.errors.general_post_details_general_seo_title ? (
                        <div>{t(formik.errors.general_post_details_general_seo_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_general_post_details_general_seo_description_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.general_post_details_general_seo_description &&
                        formik.errors.general_post_details_general_seo_description
                          ? 'form-field-error'
                          : formik.touched.general_post_details_general_seo_description &&
                            !formik.errors.general_post_details_general_seo_description
                          ? 'form-field-success'
                          : '')
                      }
                      as="textarea"
                      rows={3}
                      type="text"
                      name="general_post_details_general_seo_description"
                      placeholder={t(
                        'page.general_setting_general_post_details_general_seo_description_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'general_post_details_general_seo_description',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_post_details_general_seo_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.general_post_details_general_seo_description &&
                      formik.errors.general_post_details_general_seo_description ? (
                        <div>{t(formik.errors.general_post_details_general_seo_description)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.general_post_setting_favicon_logo')}
                        </Form.Label>
                        <Form.Control
                          type="file"
                          hidden
                          ref={faviconRef}
                          name="general_post_page_general_favicon_logo"
                          accept=".jpg, .jpeg, .png, .svg"
                          onChange={(event) => {
                            formik.setFieldValue(
                              'general_post_page_general_favicon_logo',
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <Button
                          type="button"
                          className="upload-button"
                          onClick={() => faviconRef.current?.click()}>
                          {t('page.file_upload')}
                        </Button>
                        <div className="preview-image">
                          {formik.values.general_post_page_general_favicon_logo && (
                            <div>
                              <img
                                src={imagePreviewFormik(
                                  formik.values.general_post_page_general_favicon_logo
                                )}
                                width="100px"
                                alt="profile_img"
                              />
                              <FontAwesomeIcon
                                icon={faClose}
                                onClick={() => {
                                  formik.setFieldValue(
                                    'general_post_page_general_favicon_logo',
                                    ''
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Form.Group>
                      <div className="form-field-error-text">
                        {formik.touched.general_post_page_general_favicon_logo &&
                        formik.errors.general_post_page_general_favicon_logo ? (
                          <div>{t(formik.errors.general_post_page_general_favicon_logo)}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                    {' '}
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
        <Row>
          <Col md={6}>
            <ul className="list-unstyled">
              <li>{t('page.post_seo_setting_note')}</li>
              <li>{t('page.post_seo_setting_note_city')}</li>
              <li>{t('page.post_seo_setting_note_state')}</li>
              <li>{t('page.post_seo_setting_note_category')}</li>
              <li>{t('page.post_seo_setting_note_ads_title')}</li>
              <li>{t('page.post_seo_setting_note_ads_decription')}</li>
            </ul>
          </Col>
        </Row>
      </Card>
    </>
  );
};

GeneralPostSeoSettingPage.propTypes = {
  t: PropTypes.func,
  isDirtyForm: PropTypes.any,
};

export default GeneralPostSeoSettingPage;
