import { React, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Field, useFormik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';

import 'assets/scss/page/_general_setting.scss';
import validationSchema from './GeneralSettingValidation';
import {
  useStoreSettingData,
  useGetSettingDataAlways,
  useGetAllSettingData,
  usePostAdminStatusChange,
} from 'hooks';
import { addSetting } from 'store';
import { AlertCommon, CommonHelmet, NavItemsCommon, TNButton } from 'common';
import { imagePreviewFormik } from 'helpers';
import { DASHBOARD, mailNotificationNavItems } from 'config';

const GeneralSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardRef = useRef();
  const faviconRef = useRef();
  const loginRef = useRef();
  const emailRef = useRef();
  const homeImageRef = useRef();
  const [getSettingData, setSettingData] = useState();

  useGetAllSettingData(
    ({ data: setting }) => {
      const dataStore = {
        home_page_general_header_logo: setting.home_page_general_header_logo,
        home_page_general_login_logo: setting.home_page_general_login_logo,
        home_page_general_email_logo: setting?.home_page_general_email_logo,
        home_page_general_home_page_logo: setting?.home_page_general_home_page_logo,
        home_page_general_favicon_logo: setting?.home_page_general_favicon_logo,
        home_page_general_seo_title: setting.home_page_general_seo_title,
        home_page_general_seo_description: setting.home_page_general_seo_description,
        home_page_general_email_address: setting.home_page_general_email_address,
        home_page_general_post_approval_status: setting.home_page_general_post_approval_status,
        home_page_general_free_post_hours_limit: setting.home_page_general_free_post_hours_limit,
        home_page_general_free_post_limit: setting.home_page_general_free_post_limit,
        setting_get: false,
      };
      setSettingData(dataStore);
      dispatch(addSetting(dataStore));
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDError,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    const dataStore = {
      home_page_general_header_logo: response.data.home_page_general_header_logo,
      home_page_general_login_logo: response.data.home_page_general_login_logo,
      home_page_general_email_logo: response.data?.home_page_general_email_logo,
      home_page_general_home_page_logo: response.data?.home_page_general_home_page_logo,
      home_page_general_favicon_logo: response.data?.home_page_general_favicon_logo,
      home_page_general_seo_title: response.data.home_page_general_seo_title,
      home_page_general_seo_description: response.data.home_page_general_seo_description,
      home_page_general_email_address: response.data.home_page_general_email_address,
      home_page_general_post_approval_status: response.data.home_page_general_post_approval_status,
      home_page_general_free_post_hours_limit:
        response.data.home_page_general_free_post_hours_limit,
      home_page_general_free_post_limit: response.data.home_page_general_free_post_limit,
      setting_get: true,
    };
    dispatch(addSetting(dataStore));
    setTimeout(function () {
      navigate(DASHBOARD);
    }, 1500);
  });

  const { mutate: doPostAdminStatus } = usePostAdminStatusChange((response) => {});

  /**
   * !This block will call on click cancel
   */
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(DASHBOARD, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(DASHBOARD, { replace: true });
    }
  };

  useGetSettingDataAlways(
    ({ data: setting }) => {
      if (setting) {
        formik.values.home_page_general_home_page_logo = setting?.home_page_general_home_page_logo;
        formik.values.home_page_general_email_logo = setting?.home_page_general_email_logo;
        formik.values.home_page_general_header_logo = setting?.home_page_general_header_logo;
        formik.values.home_page_general_login_logo = setting?.home_page_general_login_logo;
        formik.values.home_page_general_favicon_logo = setting?.home_page_general_favicon_logo;
        formik.values.home_page_general_seo_title = setting?.home_page_general_seo_title;
        formik.values.home_page_general_seo_description =
          setting?.home_page_general_seo_description;
        formik.values.home_page_general_email_address = setting?.home_page_general_email_address;
        formik.values.home_page_general_post_approval_status =
          setting?.home_page_general_post_approval_status;
        formik.values.home_page_general_free_post_hours_limit =
          setting?.home_page_general_free_post_hours_limit;
        formik.values.home_page_general_free_post_limit =
          setting?.home_page_general_free_post_limit;
      }
      dispatch(addSetting(setting));
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  /**
   * !This API will call when user click on Submit Button
   */
  const formik = useFormik({
    initialValues: {
      home_page_general_home_page_logo: getSettingData?.home_page_general_home_page_logo,
      home_page_general_email_logo: getSettingData?.home_page_general_email_logo,
      home_page_general_header_logo: getSettingData?.home_page_general_header_logo,
      home_page_general_login_logo: getSettingData?.home_page_general_login_logo,
      home_page_general_favicon_logo: getSettingData?.home_page_general_favicon_logo,
      home_page_general_seo_title: getSettingData?.home_page_general_seo_title,
      home_page_general_seo_description: getSettingData?.home_page_general_seo_description,
      home_page_general_email_address: getSettingData?.home_page_general_email_address ?? '',
      home_page_general_post_approval_status:
        getSettingData?.home_page_general_post_approval_status,
      home_page_general_free_post_hours_limit:
        getSettingData?.home_page_general_free_post_hours_limit,
      home_page_general_free_post_limit: getSettingData?.home_page_general_free_post_limit,
    },
    validationSchema,
    onSubmit: (values) => {
      if (values?.home_page_general_post_approval_status == '2') {
        doPostAdminStatus({ ad_admin_status: 1, ad_status: 3 });
      }
      doStoreSettingData(values);
    },
  });

  return (
    <>
      <CommonHelmet
        title={'page.seo_title_general_setting'}
        description={'page.seo_description_general_setting'}
      />
      <NavItemsCommon navItemName={mailNotificationNavItems} t={t} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addGDError}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box p-3">
        <h1 className="page-heading-center">{t('page.general_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_dashboard_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={dashboardRef}
                      name="home_page_general_header_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_header_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => dashboardRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.home_page_general_header_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_header_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_header_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_header_logo &&
                    formik.errors.home_page_general_header_logo ? (
                      <div>{t(formik.errors.home_page_general_header_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_favicon_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={faviconRef}
                      name="home_page_general_favicon_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_favicon_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => faviconRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.home_page_general_favicon_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_favicon_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_favicon_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_favicon_logo &&
                    formik.errors.home_page_general_favicon_logo ? (
                      <div>{t(formik.errors.home_page_general_favicon_logo)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_login_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={loginRef}
                      name="home_page_general_login_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_login_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => loginRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.home_page_general_login_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_login_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_login_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_login_logo &&
                    formik.errors.home_page_general_login_logo ? (
                      <div>{t(formik.errors.home_page_general_login_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_email_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={emailRef}
                      name="home_page_general_email_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_email_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => emailRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.home_page_general_email_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_email_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_email_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_email_logo &&
                    formik.errors.home_page_general_email_logo ? (
                      <div>{t(formik.errors.home_page_general_email_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={homeImageRef}
                      name="home_page_general_home_page_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_home_page_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => homeImageRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.home_page_general_home_page_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_home_page_logo)}
                            width="100px"
                            className="diff-img"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_home_page_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_home_page_logo &&
                    formik.errors.home_page_general_home_page_logo ? (
                      <div>{t(formik.errors.home_page_general_home_page_logo)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_general_seo_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_seo_title &&
                        formik.errors.home_page_general_seo_title
                          ? 'form-field-error'
                          : formik.touched.home_page_general_seo_title &&
                            !formik.errors.home_page_general_seo_title
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="home_page_general_seo_title"
                      placeholder={t(
                        'page.general_setting_home_page_general_seo_title_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_page_general_seo_title',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_seo_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_seo_title &&
                      formik.errors.home_page_general_seo_title ? (
                        <div>{t(formik.errors.home_page_general_seo_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_general_seo_description_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_seo_description &&
                        formik.errors.home_page_general_seo_description
                          ? 'form-field-error'
                          : formik.touched.home_page_general_seo_description &&
                            !formik.errors.home_page_general_seo_description
                          ? 'form-field-success'
                          : '')
                      }
                      as="textarea"
                      rows={3}
                      type="text"
                      name="home_page_general_seo_description"
                      placeholder={t(
                        'page.general_setting_home_page_general_seo_description_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_page_general_seo_description',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_seo_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_seo_description &&
                      formik.errors.home_page_general_seo_description ? (
                        <div>{t(formik.errors.home_page_general_seo_description)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top me-2">
                      {t('page.general_setting_general_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_email_address &&
                        formik.errors.home_page_general_email_address
                          ? 'form-field-error'
                          : formik.touched.home_page_general_email_address &&
                            !formik.errors.home_page_general_email_address
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="home_page_general_email_address"
                      placeholder={t('page.general_setting_general_email_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_page_general_email_address',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_email_address}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_email_address &&
                      formik.errors.home_page_general_email_address ? (
                        <div>{t(formik.errors.home_page_general_email_address)}</div>
                      ) : null}
                    </div>
                  </Form.Group>

                  <Form.Group className="field-label-top me-5 d-flex align-item-center">
                    <Form.Label className="field-label-top me-2 mt-2">
                      {t('page.home_page_general_post_approval_status')}
                    </Form.Label>
                    <Form.Check
                      type="switch"
                      name="home_page_general_post_approval_status"
                      label=""
                      checked={formik.values.home_page_general_post_approval_status === '1'}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        formik.setFieldValue(
                          'home_page_general_post_approval_status',
                          checked ? '1' : '2'
                        );
                      }}
                      id="custom-switch"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_general_free_post_hours_limit_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_free_post_hours_limit &&
                        formik.errors.home_page_general_free_post_hours_limit
                          ? 'form-field-error'
                          : formik.touched.home_page_general_free_post_hours_limit &&
                            !formik.errors.home_page_general_free_post_hours_limit
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="home_page_general_free_post_hours_limit"
                      placeholder={t(
                        'page.general_setting_home_page_general_free_post_hours_limit_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_page_general_free_post_hours_limit', value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_free_post_hours_limit}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_free_post_hours_limit &&
                      formik.errors.home_page_general_free_post_hours_limit ? (
                        <div>{t(formik.errors.home_page_general_free_post_hours_limit)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_general_free_post_limit_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_free_post_limit &&
                        formik.errors.home_page_general_free_post_limit
                          ? 'form-field-error'
                          : formik.touched.home_page_general_free_post_limit &&
                            !formik.errors.home_page_general_free_post_limit
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="home_page_general_free_post_limit"
                      placeholder={t(
                        'page.general_setting_home_page_general_free_post_limit_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_page_general_free_post_limit', value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_free_post_limit}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_free_post_limit &&
                      formik.errors.home_page_general_free_post_limit ? (
                        <div>{t(formik.errors.home_page_general_free_post_limit)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="primary-button">
                <span className="link-center" onClick={handleCancel}>
                  {t('page.general_setting_cancel_link')}
                </span>
                <TNButton
                  type="submit"
                  isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                  {' '}
                  {t('page.general_setting_save_button')}
                </TNButton>
              </div>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
GeneralSetting.propTypes = {
  t: PropTypes.func,
  isDirtyForm: PropTypes.any,
};
export default GeneralSetting;
