import client from 'libs/HttpClient';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { storeToken } from 'store';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async (dispatch) => {
  if ('Notification' in window) {
    const hasAlertedUser = localStorage.getItem('hasAlertedUser');
    const permission = await Notification.requestPermission();

    if (permission === 'denied' && !hasAlertedUser) {
      localStorage.setItem('hasAlertedUser', 'true');
    } else if (permission === 'granted') {
      generateToken(dispatch);
    }
  }
};

const generateToken = async (dispatch) => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY,
    });

    if (token) {
      dispatch(storeToken(token));
      await client.post('/notifications/store', { token });
    } else {
      console.error('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('Error generating FCM token:', error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
