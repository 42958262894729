// CommonHelmet.js
import { useGetAllSettingData } from 'hooks';
import { t } from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet';

const CommonHelmet = ({ title, description }) => {
  const { data: setting } = useGetAllSettingData(
    () => {},
    (error) => {
      if (error?.data?.redirect == true) {
        console.error('Error occurred: ', error.message);
      }
    }
  );
  const descriptions = t(description) || setting?.data?.home_page_general_seo_description;
  const titles =
    setting?.data?.home_page_general_seo_title + ' | ' + t(title) ||
    t('page.home_page_general_seo_title');

  return (
    <Helmet>
      {setting?.data?.home_page_general_favicon_logo && (
        <link rel="icon" href={setting?.data?.home_page_general_favicon_logo} />
      )}
      <title>{titles.includes('undefined') == false ? titles : t('page.url_title_common')}</title>
      <meta name="title" content={titles} />
      <meta name="description" content={descriptions} />
      <meta name="og:title" content={titles} />
      <meta name="og:description" content={descriptions} />
    </Helmet>
  );
};

export { CommonHelmet };
