/* eslint-disable prettier/prettier */
import { faEllipsisV, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef } from 'react';

import 'assets/scss/page/_chat.scss';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Spinner,
  Modal,
  Form,
} from 'react-bootstrap';
import {
  useAddChatMessage,
  useAddChatRoom,
  useClearChat,
  useDeleteChat,
  useDeleteMsg,
  useListChatMessages,
  useListChatRooms,
  useListChatUsers,
  useMarkRead,
  useReportMsg,
} from 'hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import pdfIcon from 'assets/images/pdf_icon.svg';
import Select from 'react-select';
import default_chat_user from 'assets/images/default_chat_user.png';
import { USERS, VIEW } from 'config';

const customStyle = {
  control: (base) => ({
    ...base,
    borderRadius: '8px', // Custom border radius
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    padding: '4px 8px', // Custom padding
    borderColor: '#ccc', // Custom border color
    '&:hover': {
      borderColor: '#888', // Border color on hover
    },
    minWidth: '232px',
    maxWidth: '232px',
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? 'linear-gradient(120.11deg, #416957 23.9%, #1a5745 101.37%)' // Change background when selected
      : state.isFocused
      ? '#416957' // Change background when hovered (focused)
      : 'transparent', // Default background
    color: state.isSelected
      ? 'white' // Text color when selected
      : state.isFocused
      ? 'white' // Text color when hovered
      : 'black', // Default text color
    padding: '10px 20px', // Padding inside the options
    fontSize: '16px', // Font size of options
    cursor: 'pointer',
  }),

  indicatorsContainer: (base) => ({
    ...base,
    display: 'none', // Remove the padding between the cross icon and dropdown arrow
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none', // Hide the dropdown arrow
  }),
  input: (base) => ({
    ...base,

    fontSize: '16px', // Custom font size
    fontWeight: 'bold', // Custom font weight
  }),
  placeholder: (base) => ({
    ...base,
    color: 'gray', // Custom placeholder color
    fontSize: '14px', // Custom placeholder font size
  }),
};
const UserChat = ({ socket, t }) => {
  const location = useLocation();
  const { sender_id, chat_room_id } = location.state || {};
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const [activeChatRoomId, setActiveChatRoomId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [chatOpen, setChatOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleteChat, setIsDeleteChat] = useState(false);
  const messagesEndRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const messageContainerRef = useRef(null);
  const [topMessage, setTopMessage] = useState(null);
  const [isHalfScrolled, setIsHalfScrolled] = useState(false);
  const [reportError, setReportError] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (sender_id != undefined && chat_room_id != undefined) {
      setActiveUserId(sender_id);
      setActiveChatRoomId(chat_room_id);
    }
  }, [sender_id]);

  const { refetch: getUsers } = useListChatUsers(
    (res) => {
      const formattedData = res?.data?.map((user) => ({
        value: user?.user_id,
        label: user?.full_name,
        profile_image: user?.profile_image || default_chat_user,
      }));
      setUserOptions(formattedData);
    },
    (error) => {
      console.error('error:', error);
    }
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  let receiver_name = null;
  let receiver_profile = null;
  const handleSelectChange = async (selectedOption) => {
    const user = users.find((user) => user.id == selectedOption.value);
    if (user) {
      setActiveUserId(user.id);
      setActiveChatRoomId(user.chat_room_id);
    } else {
      const receiver_id = selectedOption?.value;
      receiver_name = selectedOption.label;
      receiver_profile = selectedOption.profile_image;
      await createRoom({ receiver_id });
    }

    setSelectedUser(null);
  };
  const { mutate: createRoom, isLoading: creatingRoom } = useAddChatRoom(
    (res) => {
      if (res?.data) {
        const { sender_id, receiver_id, chat_room_id } = res.data?.chat_room;

        const sender_name = user?.user?.full_name;

        setActiveChatRoomId(chat_room_id);
        setActiveUserId(receiver_id);

        if (sender_id && receiver_id && chat_room_id && sender_name) {
          socket.emit('create_room', {
            chat_room_id,
            sender_id,
            receiver_id,
            sender_name,
            sender_profile: user?.user?.profile_image,
            time: moment(new Date()).format('hh:mm A'),
            date: moment(new Date()).format('DD/MM/YYYY'),
          });
          const roomData = {
            id: receiver_id,
            name: receiver_name,
            profile: receiver_profile,
            chat_room_id: chat_room_id,
          };

          setUsers((prevUsers) => {
            const isRoomExist = prevUsers?.some(
              (user) => user?.chat_room_id == roomData?.chat_room_id
            );

            if (!isRoomExist) {
              return [...prevUsers, roomData];
            }
            return prevUsers;
          });
        } else {
          console.error('Missing data in response:', res?.data);
        }
      } else {
        console.error('Response data is missing or invalid:', res);
      }
    },
    (error) => {
      console.error('Error creating chat room:', error);
    }
  );

  const { refetch: getChatRooms } = useListChatRooms(
    (res) => {
      const userData = res?.data?.chat_rooms
        ?.filter((chat) => chat?.deleted_by_user != user?.user?.user_id)
        ?.map((room) => {
          const isReceiver = room.receiver_id == user?.user?.user_id;
          const userInfo = isReceiver
            ? {
                id: room?.sender_id,
                name: room?.sender_name,
                last_seen: room?.last_message_created_at,
                profile: room?.sender_profile,
              }
            : {
                id: room?.receiver_id,
                name: room?.receiver_name,
                last_seen: room?.last_message_created_at,
                profile: room?.receiver_profile,
              };
          const unreadCount = room?.from_user_id == user?.user?.user_id ? room?.unread_count : 0;
          return {
            id: userInfo.id,
            name: userInfo.name,
            last_seen: userInfo.last_seen,
            unread_count: unreadCount,
            chat_room_id: room?.chat_room_id,
            last_message:
              room?.last_message_deleted_by != user?.user?.user_id
                ? room?.message_type === 2
                  ? 'Image'
                  : room?.message_type === 3
                  ? 'PDF'
                  : room?.last_message
                : null,
            profile: userInfo.profile,
            last_message_created_at: room?.last_message_created_at,
          };
        });

      const sortedUserData = userData.sort((a, b) => {
        return new Date(b.last_message_created_at) - new Date(a.last_message_created_at);
      });

      setActiveUserId(userData[0]?.id);
      setActiveChatRoomId(userData[0]?.chat_room_id);

      setUsers(userData.reverse());
    },
    (error) => {
      console.error('error: ', error);
    }
  );

  const { data: chatMessages, refetch } = useListChatMessages(
    { chat_room_id: activeChatRoomId },
    (res) => {
      const msg = res?.data
        ?.filter((msg) => msg?.deleted_by_user != user?.user?.user_id)
        .map((msg) => {
          return {
            chat_message_id: msg?.chat_message_id,
            chat_room_id: msg?.chat_room_id,
            user_id: Number(msg?.to_user_id),
            text: msg?.message,
            message_type: msg?.message_type,
            sender: Number(msg?.from_user_id),
            time: moment(msg?.created_at).format('hh:mm A'),
            date: moment(msg?.created_at).format('DD/MM/YYYY'),
            file: msg?.file_path || null,
          };
        });
      setMessages(msg);
      setScroll(true);
    },
    (error) => {
      console.error('error: ', error);
    }
  );

  const { mutateAsync: sendMessage, isLoading } = useAddChatMessage(
    (res) => {},
    (error) => {
      toast.error(error?.message);
    }
  );
  const { mutate: clearChat } = useClearChat(
    (res) => {},
    (error) => {
      toast.error(error?.message);
    }
  );
  const { mutate: deleteChat } = useDeleteChat(
    (res) => {},
    (error) => {
      toast.error(error?.message);
    }
  );
  const { mutate: markRead } = useMarkRead(
    (res) => {},
    (error) => {
      toast.error(error?.message);
    }
  );
  const { mutate: deleteMsg } = useDeleteMsg(
    (res) => {},
    (error) => {
      toast.error(error?.message);
    }
  );
  const { mutate: reportMsg } = useReportMsg(
    (res) => {
      toast.error(res?.message);
    },
    (error) => {
      toast.error(error?.message);
    }
  );
  useEffect(() => {
    if (activeChatRoomId != null) {
      markRead({ chat_room_id: activeChatRoomId });
      socket.emit('read_message', {
        chat_room_id: activeChatRoomId,
        sender_id: user?.user?.user_id,
      });
    }
  }, [activeChatRoomId]);

  useEffect(() => {
    socket.emit('register', user?.user?.user_id);
    socket.on('chat_connected', (data) => {});
    socket.on('message', async (data) => {
      const chatRoomExists = users.some((user) => user.chat_room_id == data.chat_room_id);
      setScroll(true);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          chat_message_id: data?.chat_message_id,
          chat_room_id: data?.chat_room_id,
          user_id: Number(data?.to_user_id),
          text: data?.message,
          message_type: data?.message_type,
          sender: Number(data?.from_user_id),
          time: data?.time,
          date: data?.date,
          file: data?.file || null,
        },
      ]);

      setUsers((prevUsers) => {
        return prevUsers?.map((user) => {
          if (user?.chat_room_id == data?.chat_room_id) {
            if (user?.chat_room_id == activeChatRoomId) {
              return {
                ...user,
                unread_count: 0,
                last_message: data?.last_message,
              };
            } else {
              const newUnreadCount = user?.unread_count === undefined ? 1 : user?.unread_count + 1;
              return {
                ...user,
                unread_count: newUnreadCount,
                last_message: data?.last_message,
              };
            }
          }
          return user;
        });
      });
    });

    socket.on('connect_error', (error) => {
      console.error('Connection failed:', error);
    });
  }, []);

  useEffect(() => {
    if (imageFile != null || pdfFile != null) {
      handleSendMessage();
    }
  }, [imageFile || pdfFile]);
  socket.on('read_message', (data) => {
    const { chat_room_id } = data;
    setUsers((prevUsers) => {
      return prevUsers?.map((user) => {
        if (user.chat_room_id == chat_room_id) {
          return {
            ...user,
            unread_count: 0,
          };
        }
        return user;
      });
    });
  });

  socket.on('create_room', (data) => {
    const roomData = {
      id: data?.sender_id,
      name: data?.sender_name,
      chat_room_id: data?.chat_room_id,
      profile: data?.sender_profile,
      time: data?.time,
      date: data?.date,
    };
    setUsers((prevUsers) => {
      const isRoomExist = prevUsers.some((user) => user.id == roomData.id);

      if (!isRoomExist) {
        setActiveChatRoomId(roomData?.chat_room_id);
        setActiveUserId(roomData?.id);
        return [...prevUsers, roomData];
      } else {
        return prevUsers;
      }
    });
  });
  socket.on('delete_msg', (data) => {
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.filter((msg) => msg.chat_message_id != data?.chat_id);
      if (updatedMessages.length == 0) {
        setTopMessage(null);
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.chat_room_id == activeChatRoomId ? { ...user, last_message: null } : user
          )
        );
      }

      return updatedMessages;
    });
  });

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    setImagePreview(null);
    if (message.trim() !== '' || imageFile || pdfFile) {
      let messageType = 1; // Default is text
      if (imageFile) {
        messageType = 2; // Image
      } else if (pdfFile) {
        messageType = 3; // PDF
      }

      if (messageType != 1) {
        const datas = await sendMessage({
          chat_room_id: activeChatRoomId,
          to_user_id: activeUserId,
          message_type: messageType,
          message: message,
          image_path: imageFile || pdfFile || null,
        });
        const messageData = {
          chat_message_id: datas?.data?.chat_message_id,
          chat_room_id: activeChatRoomId,
          to_user_id: activeUserId,
          message_type: messageType,
          message: message,
          from_user_id: user?.user?.user_id,
          time: moment(new Date()).format('hh:mm A'),
          date: moment(new Date()).format('DD/MM/YYYY'),
          file: datas?.data?.file_path,
        };
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            chat_message_id: datas?.data?.chat_message_id,
            chat_room_id: activeChatRoomId,
            user_id: activeUserId,
            text: message,
            message_type: messageType,
            sender: user?.user?.user_id,
            time: moment(new Date()).format('hh:mm A'),
            date: moment(new Date()).format('DD/MM/YYYY'),
            file: datas?.data?.file_path,
          },
        ]);
        socket.emit('message', messageData);
      } else {
        const datas = await sendMessage({
          chat_room_id: activeChatRoomId,
          to_user_id: activeUserId,
          message_type: messageType,
          message: message,
          image_path: imageFile || pdfFile || null,
        });
        const messageData = {
          chat_message_id: datas?.data?.chat_message_id,
          chat_room_id: activeChatRoomId,
          to_user_id: activeUserId,
          message_type: messageType,
          message: message,
          from_user_id: user?.user?.user_id,
          time: moment(new Date()).format('hh:mm A'),
          date: moment(new Date()).format('DD/MM/YYYY'),
          file: null,
        };
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            chat_message_id: datas?.data?.chat_message_id,
            chat_room_id: activeChatRoomId,
            user_id: activeUserId,
            text: message,
            message_type: messageType,
            sender: user?.user?.user_id,
            time: moment(new Date()).format('hh:mm A'),
            date: moment(new Date()).format('DD/MM/YYYY'),
            file: null,
          },
        ]);
        socket.emit('message', messageData);
      }
      setMessage('');
      setImageFile(null);
      setPdfFile(null);
      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) => {
          if (user?.chat_room_id == activeChatRoomId) {
            return {
              ...user,
              unread_count: 0,
              time: moment(new Date()).format('hh:mm A'),
              last_message: messageType === 2 ? 'Image' : messageType === 3 ? 'PDF' : message,
            };
          }
          return user;
        });

        return updatedUsers.sort((a, b) => {
          if (a.chat_room_id == activeChatRoomId) {
            return 1;
          }
          if (b.chat_room_id == activeChatRoomId) {
            return -1;
          }
          return 0;
        });
      });
    }
  };

  const handleUserClick = (user_id, chat_room_id) => {
    setActiveChatRoomId(chat_room_id);
    setActiveUserId(user_id);
    setSidebarOpen(false);
    setChatOpen(true);
    socket.emit('chat_connected', { chat_room_id, sender_id: user_id });
    setMessages(messages.filter((msg) => msg.chat_room_id == chat_room_id));
    setScroll(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearChat = (sender_id) => {
    setMessages([]);
    setTopMessage(null);
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.chat_room_id == activeChatRoomId ? { ...user, last_message: null } : user
      )
    );
    clearChat({ sender_id, chat_room_id: activeChatRoomId });
  };
  const handleReportChat = (chat_id) => {
    setChatId(chat_id);
    setShowModal(true);
  };
  const handleDeleteMsg = (chat_id, status) => {
    if (status == 0) {
      setChatId(chat_id);
      setShowDeleteModal(true);
    } else {
      setScroll(false);

      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.filter((msg) => msg.chat_message_id != chat_id);

        if (updatedMessages.length == 0) {
          setTopMessage(null);

          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.chat_room_id == activeChatRoomId ? { ...user, last_message: null } : user
            )
          );
        }

        return updatedMessages;
      });
      socket.emit('delete_msg', {
        receiver_id: Number(activeUserId),
        chat_id: chat_id,
      });

      deleteMsg({ chat_id });
      setReason('');
      setShowDeleteModal(false);
    }
  };

  const handleDeleteChat = (receiver_id, status) => {
    if (status == 0) {
      setChatId(receiver_id);
      setShowDeleteModal(true);
      setIsDeleteChat(true);
    } else {
      setUsers(users.filter((user) => user.id != activeUserId));
      setScroll(false);
      setMessages(messages.filter((msg) => msg.user_id != activeChatRoomId));
      setActiveUserId(null);

      deleteChat({ chat_room_id: activeChatRoomId });
      setShowDeleteModal(false);
      setIsDeleteChat(false);
      setReason('');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType.startsWith('image/')) {
        setImageFile(file);
        setImagePreview(URL.createObjectURL(file));
      } else if (fileType === 'application/pdf') {
        setPdfFile(file);
      } else {
        alert('Please select an image or PDF file.');
      }
    }
  };

  useEffect(() => {
    if (scroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    handleScroll();
  }, [messages]);

  const handleScroll = () => {
    const container = messageContainerRef?.current;
    const containerHeight = container?.clientHeight;
    const scrollPosition = container?.scrollTop;
    const scrollHeight = container?.scrollHeight;

    const distanceFromTop = scrollPosition;

    if (distanceFromTop == 0 && !isHalfScrolled) {
      setIsHalfScrolled(true);
    }
    if (distanceFromTop > 0 && isHalfScrolled) {
      setIsHalfScrolled(false);
    }

    // // Check if user has scrolled to the halfway point
    // if (scrollPosition >= halfway && !isHalfScrolled) {
    //   setIsHalfScrolled(true);
    //   // Trigger any function here, like loading older messages, if applicable
    // } else if (scrollPosition < halfway && isHalfScrolled) {
    //   setIsHalfScrolled(false);
    // }

    const firstVisibleMessage = getTopMessage(container);
    if (firstVisibleMessage) {
      setTopMessage(firstVisibleMessage);
    }
  };

  const getTopMessage = (container) => {
    const messages = container?.querySelectorAll('.message-item');
    let topMessage = null;

    messages?.forEach((msg) => {
      const messageRect = msg.getBoundingClientRect();

      if (messageRect.top >= 0 && messageRect.top <= container.clientHeight) {
        topMessage = msg;
      }
    });

    if (topMessage) {
      const messageDate = topMessage.dataset.date;
      return formatDate(messageDate);
    }

    return null;
  };

  const formatDate = (dateString) => {
    const today = moment();
    const messageDate = moment(dateString, 'DD/MM/YYYY');

    if (messageDate.isSame(today, 'day')) {
      return 'Today';
    }

    if (messageDate.isSame(today.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday';
    }

    if (messageDate.isAfter(today.clone().subtract(7, 'days'))) {
      return messageDate.format('ddd, D MMM');
    }
    return messageDate.format('DD/MM/YYYY');
  };

  const [reason, setReason] = useState('');

  const handleInputChange = (e) => {
    setReason(e.target.value);
  };

  const handleReportClick = () => {
    if (reason.trim() === '') {
      setReportError(t('page.report_required'));
      return;
    }
    setShowModal(false);
    setReason('');

    const reportData = {
      reported_by: user?.user?.user_id,
      reported_id: chatId,
      report_type: 3,
      reason: reason,
    };
    if (reason.length < 255) {
      reportMsg(reportData);
    } else {
      toast.error(t('page.report_text_max_length'));
    }
  };

  const [currentView, setCurrentView] = useState('desktop');
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaChange = (e) => {
      setCurrentView(e.matches ? 'phone' : 'desktop');
    };
    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);
    return () => {
      mediaQuery.removeListener(handleMediaChange);
    };
  }, []);

  const handleBack = () => {
    setChatOpen(false);
    setSidebarOpen(true);
  };
  const filteredUsers = users
    ?.filter((user) => user?.name?.toLowerCase().includes(searchQuery.toLowerCase()))
    .reverse();
  const handlePdfClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };
  return (
    <>
      <Container fluid className="text-light  chat-main">
        <Row className="h-100">
          <Col
            md={4}
            lg={3}
            className={`border-sidebar p-0 ${
              currentView == 'phone' && sidebarOpen ? 'd-block d-md-none' : 'd-none d-md-block'
            }`}>
            <div className="px-3">
              <InputGroup className="my-3 mb-4 search-group">
                <Button>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                {/* <FormControl
                  placeholder="Search users..."
                  className="search-chat-input"
                  value={searchQuery}
                  onChange={handleSearchChange}
                /> */}
                <Select
                  value={selectedUser}
                  onChange={handleSelectChange}
                  styles={customStyle}
                  options={userOptions.filter((user) =>
                    user?.label?.toLowerCase().includes(searchQuery.toLowerCase())
                  )} // Filter based on search query
                  isClearable
                  placeholder="Select a user..."
                />
              </InputGroup>
            </div>
            <div className="list-group align-items-center side-user-wrapper">
              {filteredUsers.length > 0 ? (
                filteredUsers?.map((user) => (
                  <div
                    key={user.chat_room_id}
                    onClick={() => handleUserClick(user.id, user.chat_room_id)}
                    className={`side-user-list-group-item list-group-item list-group-item-action ${
                      user.id == activeUserId ? 'active' : ''
                    }`}>
                    {/* <div className="d-flex justify-content-between"> */}
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src={
                          user?.profile?.includes('default.jpeg') ||
                          user?.profile?.includes('default.jpg')
                            ? default_chat_user
                            : user?.profile
                        }
                        alt=""
                        className="chat-img"
                      />

                      <span>
                        <span className="side-user-name">{user.name}</span>
                        <div className="pre-chat">
                          {user?.last_message?.length > 30
                            ? user?.last_message?.substring(0, 30) + '...'
                            : user?.last_message}
                        </div>
                      </span>
                      <span className="time-stamp">
                        {/* {user.last_seen
                          ? moment(user.last_seen).format("hh:mm A")
                          : ""} */}
                        {user.last_seen
                          ? moment(user.last_seen).isSame(moment(), 'day')
                            ? moment(user.last_seen).format('hh:mm A')
                            : moment(user.last_seen).isSame(moment().subtract(1, 'day'), 'day')
                            ? 'Yesterday'
                            : moment(user.last_seen).isAfter(moment().subtract(1, 'week'))
                            ? moment(user.last_seen).format('dddd')
                            : moment(user.last_seen).format('MMM D, YYYY')
                          : ''}
                      </span>
                    </div>
                    {user.unread_count != 0 && (
                      <span className={`badge bg-secondary unread-count`}>{user.unread_count}</span>
                    )}
                    {/* </div> */}
                  </div>
                ))
              ) : (
                <>
                  <h5>{t('page.no_chat_in_listing')}</h5>
                </>
              )}
            </div>
          </Col>

          <Col
            md={8}
            lg={9}
            className={`px-0 msg-wrapper ${currentView == 'phone' && chatOpen ? 'd-block' : ''}`}>
            {activeUserId != null && users?.length > 0 ? (
              <>
                <div
                  className="d-flex justify-content-between align-items-center mb-3 p-3"
                  style={{
                    borderBottom: '1px solid #828282',
                  }}>
                  <div className="d-flex align-items-center gap-4">
                    <span className="d-md-none" onClick={handleBack}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </span>
                    <img
                      src={
                        users
                          .find((user) => user.id == activeUserId)
                          ?.profile?.includes('default.jpeg') ||
                        users
                          .find((user) => user.id == activeUserId)
                          ?.profile?.includes('default.jpg')
                          ? default_chat_user
                          : users.find((user) => user.id == activeUserId)?.profile
                      }
                      alt=""
                      className="chat-img"
                    />
                    <h5
                      className="mb-0"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(USERS + VIEW + activeUserId)}>
                      {activeUserId
                        ? users.find((user) => user.id == activeUserId)?.name
                        : 'Select a user'}
                    </h5>
                  </div>
                  <Dropdown align="end">
                    <Dropdown.Toggle
                      variant="outline-dark"
                      className="border-0 p-0 drop-fnc-btn"
                      size="sm">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleClearChat(activeUserId)}>
                        {t('page.clear_chat_txt')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDeleteChat(activeUserId, 0)}>
                        {t('page.delete_chat_txt')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div
                  className="rounded p-3 mb-3 all-msg-main-div"
                  ref={messageContainerRef}
                  onScroll={handleScroll}
                  style={{
                    overflowY: 'auto',
                  }}>
                  <div className="text-center mb-3 day-badge-parent">
                    <span className="badge day-badge">{topMessage}</span>
                  </div>
                  <div className="d-flex flex-column">
                    {messages.map(
                      (msg, index) =>
                        msg?.chat_room_id == activeChatRoomId && (
                          <div
                            key={index}
                            className={`d-flex message-item ${
                              msg.sender == user?.user?.user_id
                                ? 'justify-content-end flex-column align-items-end'
                                : 'justify-content-start flex-column align-items-start'
                            } mb-2`}
                            data-date={msg.date}>
                            <div
                              className={`rounded p-2 ${
                                msg.sender == user?.user?.user_id
                                  ? ' text-white right-msg'
                                  : ' text-dark left-msg'
                              }`}
                              style={{ maxWidth: '60%', position: 'relative' }}>
                              {msg.text}
                              <span className="drp-msg">
                                <Dropdown align="end">
                                  <Dropdown.Toggle
                                    variant="transparent"
                                    className="border-0 p-0 drop-fnc-btn"
                                    size="sm">
                                    <svg
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="black"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.41221 9.16221C7.30282 9.27157 7.15447 9.333 6.99979 9.333C6.84511 9.333 6.69677 9.27157 6.58738 9.16221L3.28746 5.86229C3.23175 5.80848 3.18731 5.74411 3.15673 5.67295C3.12616 5.60178 3.11007 5.52523 3.1094 5.44778C3.10872 5.37032 3.12348 5.29351 3.15281 5.22182C3.18214 5.15013 3.22546 5.085 3.28023 5.03023C3.335 4.97546 3.40013 4.93214 3.47182 4.90281C3.54351 4.87348 3.62032 4.85872 3.69778 4.8594C3.77523 4.86007 3.85178 4.87616 3.92295 4.90673C3.99411 4.93731 4.05848 4.98175 4.11229 5.03746L6.99979 7.92496L9.88729 5.03746C9.99731 4.9312 10.1447 4.8724 10.2976 4.87373C10.4506 4.87506 10.5969 4.93641 10.705 5.04457C10.8132 5.15272 10.8745 5.29903 10.8759 5.45198C10.8772 5.60492 10.8184 5.75228 10.7121 5.86229L7.41221 9.16221Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    className="dlt-msg-drpdwn"
                                    style={{ width: '100px' }}>
                                    {msg.sender != user?.user?.user_id ? (
                                      <Dropdown.Item
                                        onClick={() => handleReportChat(msg?.chat_message_id)}>
                                        {t('page.report_chat_txt')}
                                      </Dropdown.Item>
                                    ) : (
                                      ''
                                    )}
                                    <Dropdown.Item
                                      onClick={() => handleDeleteMsg(msg?.chat_message_id, 0)}>
                                      {t('page.delete_txt')}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </span>
                              {msg.message_type == 2 && (
                                <img
                                  src={msg.file}
                                  alt="Message File"
                                  style={{ cursor: 'pointer' }} // Add cursor pointer style for better UX
                                  onClick={() => window.open(msg.file, '_blank')} // Opens image in a new tab on click
                                />
                              )}

                              {msg.message_type == 3 && (
                                <div className="pdf-message">
                                  {/* PDF Icon that triggers PDF opening in a new tab */}
                                  <img
                                    src={pdfIcon} // PDF logo icon
                                    alt="PDF Icon"
                                    style={{
                                      cursor: 'pointer',
                                    }} // Adjust the size of the PDF icon
                                    onClick={() => handlePdfClick(msg.file)} // Trigger PDF open on click
                                  />
                                </div>
                              )}
                            </div>

                            <div className="small text-muted text-end msg-bel-time mt-1">
                              {msg.time}
                            </div>
                          </div>
                        )
                    )}

                    {isLoading && (imageFile != null || pdfFile != null) && (
                      <div className="d-flex justify-content-end">
                        <span className="spinner-bg text-center">
                          <Spinner></Spinner>
                        </span>
                      </div>
                    )}
                    <div ref={messagesEndRef} />
                  </div>
                </div>
                <div className="px-3 d-flex align-items-center">
                  <InputGroup className="ps-3 pe-2">
                    <FormControl
                      value={message}
                      onChange={handleMessageChange}
                      onKeyDown={handleKeyDown}
                      placeholder="Type a message"
                      className="bg-transparent text-dark main-message-input"
                      aria-label="Type a message"
                    />
                  </InputGroup>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="d-none"
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className="btn btn-light file-btn">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M27 15.0009L15.486 26.5149C13.8877 28.0721 11.7404 28.9371 9.50891 28.9226C7.27746 28.9081 5.14152 28.0152 3.56362 26.4373C1.98571 24.8594 1.09283 22.7234 1.0783 20.492C1.06378 18.2605 1.9288 16.1132 3.48604 14.5149L15.344 2.65685C16.4049 1.59599 17.8437 1 19.344 1C20.8443 1 22.2832 1.59599 23.344 2.65685C24.4049 3.71772 25.0009 5.15656 25.0009 6.65685C25.0009 8.15715 24.4049 9.59599 23.344 10.6569L11.828 22.1729C11.2976 22.7033 10.5782 23.0013 9.82804 23.0013C9.07789 23.0013 8.35847 22.7033 7.82804 22.1729C7.2976 21.6424 6.99961 20.923 6.99961 20.1729C6.99961 19.4227 7.2976 18.7033 7.82804 18.1729L19 7.00085"
                        stroke="#828282"
                        strokeWidth="2"
                      />
                    </svg>
                  </label>
                  <Button onClick={handleSendMessage} className="send-btn">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2826_1211)">
                        <path
                          d="M29.9071 1.41984C29.8579 1.52687 29.7902 1.62437 29.7071 1.70784L10.7991 20.6118L16.1391 29.5138C16.2337 29.6722 16.3703 29.8012 16.5336 29.8868C16.697 29.9724 16.8809 30.0111 17.0649 29.9986C17.2489 29.9862 17.4259 29.9232 17.5763 29.8164C17.7267 29.7097 17.8447 29.5634 17.9171 29.3938L29.9071 1.41984ZM9.38712 19.1998L0.485118 13.8558C0.326799 13.7612 0.197725 13.6247 0.112166 13.4613C0.0266077 13.2979 -0.0121021 13.1141 0.000317033 12.9301C0.0127361 12.7461 0.0758003 12.5691 0.182537 12.4187C0.289273 12.2683 0.435524 12.1503 0.605118 12.0778L28.5851 0.0898438C28.4765 0.13922 28.3776 0.207632 28.2931 0.291844L9.38712 19.1998Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2826_1211">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h2> {t('page.no_message_found_txt')}</h2>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="deactivate-modal">
        <Modal.Header closeButton className="d-flex border-0 pt-2">
          <Modal.Title className="mt-4 w-100">
            <div className="d-flex justify-content-center fs-2">
              {/* <DeactivateIcon /> */}
              {t('page.report_chat_header')}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-2 border-0 text-center">
          {/* <div>{t("page.deactivate_account_confirmation")}</div> */}
          <Form.Group id="reportModal">
            <Form.Control
              as="textarea"
              rows={3}
              className="w-100 report-input"
              placeholder={t('Describe your issue here...')}
              name="newEmail"
              value={reason}
              onChange={handleInputChange}
            />
            {/* <Form.Control.Feedback type="invalid">
              {t(formikEmail.errors.newEmail)}
            </Form.Control.Feedback> */}
          </Form.Group>
        </Modal.Body>
        {reason === '' && <div style={{ color: 'red', paddingLeft: '34px' }}>{reportError}</div>}
        <Modal.Footer className="border-0 justify-content-center">
          <Button variant="danger" onClick={handleReportClick}>
            {t('page.report_button_label')}
          </Button>
          <Button variant="dark" onClick={() => setShowModal(false)}>
            {t('page.cancel_button_label')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        className="deactivate-modal">
        <Modal.Header closeButton className="d-flex border-0 pt-2">
          <Modal.Title className="mt-4 w-100">
            <div className="d-flex justify-content-center fs-2">
              {/* <DeactivateIcon /> */}
              {t('page.delete_chat_header')}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-2 border-0 text-center">
          <div>{t('page.delete_chat_confirmation')}</div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button
            variant="danger"
            onClick={() => {
              isDeleteChat ? handleDeleteChat(activeUserId, 1) : handleDeleteMsg(chatId, 1);
            }}>
            {t('page.delete_button_label')}
          </Button>
          <Button variant="dark" onClick={() => setShowDeleteModal(false)}>
            {t('page.cancel_button_label')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserChat;
