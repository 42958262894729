import * as Yup from 'yup';

let validationSchema = Yup.object({
  home_page_general_seo_title: Yup.string(
    'page.general_setting_home_page_general_seo_title_required'
  ).required('page.general_setting_home_page_general_seo_title_required'),
  home_page_general_seo_description: Yup.string(
    'page.general_setting_home_page_general_seo_description_required'
  ).required('page.general_setting_home_page_general_seo_description_required'),
  home_page_general_email_address: Yup.string('page.common_setting_dirty_email_required')
    .required('page.common_setting_dirty_email_required')
    .email('page.login_email_validation_email'),
  home_page_general_email_logo: Yup.mixed('page.general_setting_email_logo_required').required(
    'page.general_setting_email_logo_required'
  ),
  home_page_general_header_logo: Yup.mixed('page.general_setting_home_logo_required').required(
    'page.general_setting_home_logo_required'
  ),
  home_page_general_login_logo: Yup.mixed('page.general_setting_login_logo_required').required(
    'page.general_setting_login_logo_required'
  ),
  home_page_general_favicon_logo: Yup.mixed('page.general_setting_favicon_icon_required').required(
    'page.general_setting_favicon_icon_required'
  ),
  home_page_general_free_post_limit: Yup.string()
    .matches(/^\d+$/, 'page.general_setting_free_post_limit_digit_validate')
    .notRequired(),
  home_page_general_free_post_hours_limit: Yup.string()
    .matches(/^\d+$/, 'page.general_setting_free_post_hours_limit_digit_validate')
    .notRequired(),
  home_page_general_post_approval_status: Yup.string().notRequired(),
});
export default validationSchema;
