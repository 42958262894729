import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import PagesRoutes from 'routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/scss/style.scss';
import { setAuthToken } from 'libs';
import { Loader } from 'common';
import { persistor, store } from 'store';
import { UserActivityProvider } from 'context/UserActivityContext';
import Notification from 'firebaseNotifications/Notification';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { t } = useTranslation();

  window.ononline = () => {
    window.location.reload(true);
  };

  const handleOnBeforeLift = () => {
    if (
      store.getState().user?.accessToken !== undefined &&
      store.getState().user?.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor} onBeforeLift={handleOnBeforeLift}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <UserActivityProvider>
                  <PagesRoutes t={t} />
                  <ToastContainer />
                  <Notification />
                </UserActivityProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    </>
  );
}
export default App;
